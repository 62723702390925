/* If you need to add @import statements, do so up here */

@import "jit-refresh.css"; /* triggers frontend rebuilds */

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --body-background: #707389;
  --body-color: #3C3127;
  --heading-color: black;
  --light-link: #DA7638;
  --dark-link: #B55522; 
}

body {
  background: var(--body-background);
  color: var(--body-color);
  font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Helvetica", "Arial", sans-serif;
  margin: 0 8px;
  font-size: 108%;
  line-height: 1.5;
}

.highlight {
  overflow-x: scroll;
  background-color: antiquewhite;
}

a {
  color: var(--dark-link); 
  text-decoration: no-underline;
  /* text-decoration-color: #ffb088; */
}

h1 {
  margin: 1rem 0 3rem;
  text-align: center;
  font-weight: 500;
  font-size: 2.5rem;
  color: var(--heading-color);
  line-height: 1.2;
} 

h2 {
  margin: 1rem 0 3rem;
  font-weight: 300;
  font-size: 2rem;
  color: var(--heading-color);
  line-height: 1.2;
  display: block;
  text-align: center;
}

h3 {
  margin: 1rem 0 1.5rem;
  font-weight: 300;
  font-size: 1.5rem;
  color: var(--heading-color);
  line-height: 1;
  display: block;
  text-align: center;
}

body > header {
  margin: 1rem;
  text-align: center;
}

body > header img {
  display: inline-block;
  width: 400px;
  max-width: 100%;
}

body > nav ul {
  margin: 2rem 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  font-size: 1.3rem;
  font-weight: bold;
}

body > nav a {
  text-decoration: none;
  color: var(--dark-link);
}

main {
  margin: 2rem auto 4rem;
  max-width: 65rem;
  min-height: auto;
  /* padding: 25px 25px 50px; */
  /* background: #e2e8f0; */
  box-shadow: 2px 3px 3px var(--body-color);
  border-radius: 3px;

  /* @media screen and (max-width: 500px) {
    padding: 16px 16px 50px;
  } */
}

#image-credit a {
  color: var(--dark-link);
}

footer {
  text-align: center;
  margin-bottom: 4rem;
  font-size: 1rem;
}

hr {
  border: none;
  border-top: 2px solid #bbb;
  margin: 3rem 0;
}

.footnotes {
  font-size: .9rem;
}

.footnotes ol {
  list-style-type: lower-roman;
}
